<template>
  <div :class="colSize" class="row q-mt-md"></div>
  <div :class="colSize" class="row q-mt-md q-py-sm">
    <div class="col-12 q-px-sm q-pt-md text-h5">Betaalgegevens</div>
    <div class="col-4 q-px-sm">
      <q-select
        :readonly="readonly || prospectInformationBlock"
        dense
        outlined
        v-if="showPaymentMethod && !prospectInformationBlock"
        v-model="payment_method"
        :options="paymentMethodOptions"
        emit-value
        map-options
        label="Betaalwijze"
        :rules="prospectInformationBlock ? [] : validate('required')"
      />
      <q-tooltip
        class="bg-primary text-body2"
        :offset="[30, 80]"
        self="top right"
        anchor="top right"
        max-width="500px"
        v-if="!disableTool"
      >
        {{
          leverancier == "Essent"
            ? "Bij een handmatige betaling worden er administratiekosten in rekening gebracht. De huidige kosten zijn te vinden op Essent.nl."
            : leverancier == "ED"
            ? "Bij een handmatige betaling worden er administratiekosten in rekening gebracht. De huidige kosten zijn te vinden op Energiedirect.nl."
            : (disableTool = true)
        }}
      </q-tooltip>
    </div>
    <div class="col-4 q-px-sm">
      <q-input
        :readonly="readonly || prospectInformationBlock"
        dense
        outlined
        v-if="component_settings?.IBAN?.enabled ?? true"
        :modelValue="masked && maskCurrent ? masked_IBAN : IBAN"
        @click="masked && !prospectInformationBlock ? (hideIBAN = true) : ''"
        @blur="maskCurrent ? (hideIBAN = false) : ''"
        @change="IBAN = $event"
        label="IBAN"
        :rules="ibanRules"
      />
      <q-tooltip class="bg-primary text-body2" :offset="[50, -130]">
        Dit wordt gebruikt voor de automatische incasso van de maandelijkse
        kosten en de verrekening van de jaarnota.
      </q-tooltip>
    </div>
    <div class="col-4 q-px-sm">
      <q-btn
        no-caps
        dense
        outlined
        v-show="
          !prospectInformationBlock &&
          (component_settings?.IBAN?.enabled ?? true)
        "
        class="text-center text-weight-bold"
        :disable="validIban || prospectInformationBlock"
        @click="validateIban"
        label="Converteer"
        color="primary"
        style="width: 100%"
        :loading="loading.iban ?? false"
      />
    </div>
  </div>
</template>

<script>
import { computed, ref, toRef } from "vue";
import { useQuasar } from "quasar";
import { useStore } from "vuex";
import { validate, validator } from "../../plugins/validation.js";
import { mask } from "../../plugins/iban.js";

export default {
  props: {
    settings: Object,
  },
  setup(props) {
    const $q = useQuasar();
    const $store = useStore();

    const paymentMethodOptions = [
      {
        label: "Automatisch",
        value: "automatisch",
      },
      {
        label: "Handmatig",
        value: "handmatig",
      },
    ];

    const maskCurrent = ref(true);
    const hideIBAN = ref(false);
    const disableTool = ref(false);

    const component_settings = toRef(props, "settings");

    const leverancier = computed(function () {
      return $store.getters.projectSpecific.Energieleverancier;
    });

    const loading = computed({
      get() {
        return $store.getters.loading ?? false;
      },
      set(newVal) {
        $store.dispatch("setLoading", {
          key: newVal[0],
          value: newVal[1],
        });
      },
    });

    const payment_method = computed({
      get() {
        return $store.getters.record?.betaalwijze ?? "";
      },
      set(newVal) {
        $store.dispatch("updateRecord", {
          key: "betaalwijze",
          value: newVal,
          validated: validator("required", newVal),
        });
      },
    });

    const IBAN = computed({
      get() {
        return $store.getters.record?.IBAN ?? "";
      },
      set(newVal) {
        maskCurrent.value = false;
        $store.dispatch("setValidIban", null);
        $store.dispatch("updateRecord", {
          key: "IBAN",
          value: newVal,
          validated: validator(
            payment_method.value == "handmatig" ? "iban-optional" : "iban",
            newVal
          ),
        });
      },
    });

    const masked = computed(function () {
      return $store.getters.projectSpecific.masked_IBAN == "true"
        ? true
        : false;
    });

    const masked_IBAN = computed(function () {
      return hideIBAN.value ? "" : mask($store.getters.record?.IBAN ?? "");
    });

    const validIban = computed(function () {
      return $store.getters.ibanValid;
    });

    const readonly = computed(
      () => ($store.getters.record?.locked || $store.getters.viewMode) ?? false
    );

    const prospectInformationBlock = computed(() =>
      $store.getters.view == "retention" ? true : false
    );

    const showPaymentMethod = computed(
      () => component_settings.value?.paymentMethod?.enabled ?? true
    );

    const ibanRules = computed(function () {
      let rules = "";
      if (maskCurrent.value && masked.value) {
        if (payment_method.value == "handmatig" && showPaymentMethod.value) {
          rules = validate("iban-masked-optional");
        } else if (!showPaymentMethod.value) {
          rules = validate("iban-masked");
        }
      } else {
        if (payment_method.value == "handmatig" && showPaymentMethod.value) {
          rules = validate("iban-optional");
        } else {
          rules = validate("iban");
        }
      }
      return rules;
    });

    return {
      colSize: computed(() => {
        return $q.screen.lt.md ? "col-12" : "col-6";
      }),
      prospectInformationBlock,
      paymentMethodOptions,
      payment_method,
      loading,
      IBAN,
      validIban,
      readonly,
      masked_IBAN,
      masked,
      maskCurrent,
      hideIBAN,
      component_settings,
      ibanRules,
      showPaymentMethod,
      leverancier,
      disableTool,
    };
  },
  methods: {
    validateIban() {
      this.loading = ["iban", true];
      this.$store.dispatch("getValidIban", {
        iban: this.IBAN,
      });
    },
    validate,
  },
};
</script>
